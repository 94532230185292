import * as React from "react"
import { Container } from "@chakra-ui/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import OurWork from "../components/ourWork"
import breadCrumbData from "../json/services/breadCrumb.json"

const OurWorkPage = () => (
  <Layout>
    <Breadcrumb
      description={breadCrumbData.description}
      title={breadCrumbData.title}
      image="who-we-work-with-breadcrumb-banner.png"
    />
    <Container maxW={"full"}>
      <OurWork />
    </Container>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default OurWorkPage
