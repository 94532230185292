import * as React from "react"
import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react"
import { Link } from "gatsby"
import ourWorkData from "../../../../json/services/ourWork.json"

export default function OurWorkCardVertical() {
  return (
    <Box
      w="100%"
      height="96%"
      mx={{ base: 0, md: "15px" }}
      my={{ base: "15px", md: "15px" }}
      bg="white"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      role="group"
      _hover={{
        bg: "linear-gradient(142.98deg, #0FA88A 13.46%, #00DB9C 92.52%)",
        color: "white",
      }}
    >
      <Link
        to="/work-detail"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        textDecoration="none !important"
      >
        <Stack
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={1}
          pt={2}
        >
          <Text
            as="p"
            fontSize={"18px"}
            fontWeight={600}
            color="#0fa88a"
            _groupHover={{ color: "white" }}
          >
            {ourWorkData.smText}
          </Text>
          <Text as="h3" fontWeight={600} fontSize="40px" mb={4}>
            {ourWorkData.lgText}
          </Text>
        </Stack>
        <Flex flex={1}>
          <Image objectFit="contain" src={ourWorkData.srcOne} />
        </Flex>
      </Link>
    </Box>
  )
}
