import React from "react"
import { Flex, Stack, Box, Text } from "@chakra-ui/react"
import OurWorkCardHorizontal from "./components/ourWorkCardHorizontal"
import OurWorkCardVertical from "./components/ourWorkCardVertical"

const OurWork = () => {
  return (
    <Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        spacing={2}
      >
        <Box width={{ base: "100%", md: "35%" }} mx={{ base: 0, md: 2 }}>
          <OurWorkCardVertical />
        </Box>
        <Box width={{ base: "100%", md: "65%" }} mx={{ base: 0, md: 2 }}>
          <OurWorkCardHorizontal /> <OurWorkCardHorizontal />
        </Box>
      </Flex>
      <Box width="100%">
        <OurWorkCardHorizontal />
      </Box>
    </Box>
  )
}

export default OurWork
