import * as React from "react"
import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react"
import { Link } from "gatsby"
import ourWorkData from "../../../../json/services/ourWork.json"

export default function OurWorkCard() {
  return (
    <Stack
      w="100%"
      mx={{ base: 0, md: "15px" }}
      my={{ base: "15px", md: "15px" }}
      bg="white"
      height="395px"
      borderWidth="1px"
      overflow="hidden"
      borderRadius="lg"
      role="group"
      _hover={{
        bg: "linear-gradient(142.98deg, #0FA88A 13.46%, #00DB9C 92.52%)",
        color: "white",
      }}
    >
      <Link to="/work-detail" height="100%" textDecoration="none !important">
        <Stack
          direction={{ base: "column", md: "row" }}
          align={"center"}
          justify="center"
          height="100%"
        >
          <Flex p={1} flex={1} align={"center"}>
            <Image
              objectFit="contain"
              boxSize="100%"
              width="50%"
              src={ourWorkData.srcTwo}
            />
          </Flex>
          <Flex
            p={1}
            flex={1}
            display="flex"
            flexDirection="column"
            align={"center"}
          >
            <Text
              as="p"
              fontSize={"18px"}
              fontWeight={600}
              color="#0fa88a"
              _groupHover={{ color: "white" }}
            >
              {ourWorkData.smText}
            </Text>
            <Text as="h3" fontWeight={600} fontSize="40px" mb={4}>
              {ourWorkData.lgText}
            </Text>
          </Flex>
        </Stack>
      </Link>
    </Stack>
  )
}
